import React from 'react';
import PropTypes from 'prop-types';
import { uploaderType } from 'atoms/Uploader/Uploader';
import DigitalLabelView from './digitalLabelView/DigitalLabelView';
import DigitalLabelUpload from './digitalLabelUpload/digitalLabelUpload';

import styles from './digitalLabel.module.scss';

const DigitalLabel = ({
  device: {
    id,
    carrier,
    currentLabelFileName,
    pendingLabelFileName,
    currentLabelPreview,
    pendingLabelPreview,
    currentLabelUrl,
    pendingLabelUrl,
    holdingLabel,
    currentGraphic: {
      record: { id: holdingLabelId },
    },
    uploader,
  },
  onUpload,
}) => {
  const isLabelView = currentLabelUrl || pendingLabelUrl;

  return (
    <div className={styles.digitalLabel}>
      {isLabelView && (
        <DigitalLabelView
          currentLabelFileName={currentLabelFileName}
          currentLabelUrl={currentLabelUrl}
          pendingLabelFileName={pendingLabelFileName}
          pendingLabelUrl={pendingLabelUrl}
          currentLabelPreview={currentLabelPreview}
          pendingLabelPreview={pendingLabelPreview}
        />
      )}
      <DigitalLabelUpload
        deviceId={id}
        carrier={carrier}
        uploader={uploader}
        onUpload={onUpload}
        holdingLabel={holdingLabel}
        holdingLabelId={`${holdingLabelId}`}
      />
    </div>
  );
};

DigitalLabel.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.string,
    carrier: PropTypes.array,
    currentLabelFileName: PropTypes.string,
    pendingLabelFileName: PropTypes.string,
    currentLabelPreview: PropTypes.string,
    pendingLabelPreview: PropTypes.string,
    currentLabelUrl: PropTypes.string,
    pendingLabelUrl: PropTypes.string,
    holdingLabel: PropTypes.bool,
    currentGraphic: PropTypes.shape({
      record: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    uploader: uploaderType,
  }),
  onUpload: PropTypes.func,
};

export default DigitalLabel;
